$( document ).ready(function() {


 
    //header scroll fixed
    $(window).scroll(function(){
      if ($(window).scrollTop() >= 150) {
        $('header').addClass('header--fixed');
      }
      else {
        $('header').removeClass('header--fixed');
      }
  });
  //end header scroll fixed
    
});
